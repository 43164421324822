<script>
	let email;
	let status;

	const handleSubmit = async () => {
		const response = await fetch('/api/newsletter', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ email })
		});
		if (response.ok) {
			status = 'success';
		} else {
			status = 'error';
		}
	};
</script>

<div class="newsletter">
	<p class="title">Subscribe to our newsletter</p>
	<p class="subtitle">Stay up-to-date with our latest news and offers</p>
	<form on:submit|preventDefault={handleSubmit}>
		<div class="form-group">
			<label>
				<input
					name="email"
					type="email"
					placeholder="Email address"
					bind:value={email}
					autocomplete="email"
					required
				/>
			</label>
			<button type="submit"> Subscribe </button>
		</div>
	</form>
	{#if status === 'success'}
		<span class="status success">Thanks for subscribing!</span>
	{:else if status === 'error'}
		<span class="status error">Something went wrong. Please try again.</span>
	{/if}
	<p class="disclaimer">
		By subscribing, you agree to receive our latest offers and updates. See our <a href="/privacy"
			>privacy statement</a
		>
		and our <a href="/terms">terms & conditions</a>.
	</p>
</div>

<style lang="scss">
	.newsletter {
		display: flex;
		flex-direction: column;
		text-align: start;
	}

	.title {
		font-size: 1.5rem;
		margin-top: 0;
		margin-bottom: 0.5rem;
		font-weight: bold;
	}

	input[type='email'] {
		padding: 0.5rem;
		border: 1px solid #ccc;
		font-size: 1rem;
		line-height: 1.5;
	}

	button {
		background-color: #000;
		color: #fff;
		border: none;
		padding: 0.5rem;
		cursor: pointer;
		font-size: 1rem;
		line-height: 1.5;
		margin: 0.5em 0;
	}

	.disclaimer {
		font-size: 0.8rem;
		margin-top: 1rem;
		max-width: 30em;
	}

	.subtitle {
		color: #666;
		margin-top: 0;
	}

	a {
		color: #000;
		text-decoration: underline;
		&:hover {
			text-decoration: underline;
			text-decoration-thickness: 2px;
		}
	}

	.status {
		&.success {
			color: green;
		}
		&.error {
			color: red;
		}
	}

	// mobile styles
	@media (max-width: 767px) {
		.newsletter {
			justify-content: center;
			text-align: center;
		}
		.disclaimer {
			align-self: center;
		}
	}
</style>
