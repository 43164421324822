<script>
	let expandedColumn = null;

	const aboutLinks = [
		{ title: 'About', href: '/about' },
		{ title: 'Blog', href: '/blog' }
	];

	const helpLinks = [
		{ title: 'FAQs', href: '/faqs' },
		{ title: 'Return Policy', href: '/return-policy' },
		// { title: 'Arrange a Return', href: '/return-arrangement' },
		{ title: 'Contact Us', href: '/contact' }
	];

	const shopLinks = [
		{ title: 'Men', href: '/shop/men' },
		{ title: 'Women', href: '/shop/women' },
		{ title: 'Bespoke', href: '/bespoke' }
		// { title: 'Refer a Friend', href: '/refer' }
	];

	const followLinks = [
		{ title: 'Instagram', href: 'https://www.instagram.com/londontradition/', target: '_blank' },
		{ title: 'Facebook', href: 'https://www.facebook.com/londontradition/', target: '_blank' },
		{ title: 'TikTok', href: 'https://www.tiktok.com/@londontradition/', target: '_blank' },
		{ title: 'Twitter', href: 'https://twitter.com/londontradition/', target: '_blank' }
	];

	function toggleColumn(index) {
		if (expandedColumn === index) {
			expandedColumn = null;
		} else {
			expandedColumn = index;
		}
	}
</script>

<div class="container">
	{#each [{ title: 'Explore', links: aboutLinks }, { title: 'Help', links: helpLinks }, { title: 'Shop', links: shopLinks }, { title: 'Follow', links: followLinks }] as column, index}
		<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
		<div class="column">
			<div
				class="column-title"
				tabindex="0"
				on:click={() => toggleColumn(index)}
				on:keydown={(event) => event.key === 'Enter' && toggleColumn(index)}
			>
				{column.title}
			</div>
			<div class="column-content" class:expanded={expandedColumn === index}>
				<ul>
					{#each column.links as link}
						<li><a href={link.href} target={link.target}>{link.title}</a></li>
					{/each}
				</ul>
			</div>
		</div>
	{/each}
</div>

<style lang="scss">
	.container {
		display: flex;
		justify-content: space-between;
		text-align: start;
		flex-grow: 0.5;

		.column {
			box-sizing: border-box;
			margin: 0 1em;

			.column-title {
				font-weight: bolder;
				font-size: 1rem;
				padding-bottom: 1em;
				border-bottom: 1px solid #ccc;
			}

			.column-content {
				margin-top: 0.5rem;
				font-size: 0.85rem;

				&.expanded {
					display: block;
				}

				ul {
					list-style: none;
					padding: 0;
				}

				li {
					margin-bottom: 0.5rem;
				}

				a {
					color: black;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

		@media (max-width: 1023px) {
			flex-direction: column;
			padding: 0 1em 1em 1em;
			min-width: 40%;

			.column {
				flex-basis: 100%;
				text-align: center;
			}

			.column-title {
				padding-top: 1em;
				cursor: pointer;
			}

			.column-content {
				display: none;
			}
		}

		@media (max-width: 767px) {
			padding: 0;
			width: 100%;
		}
	}
</style>
