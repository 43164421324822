<script>
	export let location = 'right';
	export let className = '';

	let isSidebarOpen = false;
	let sidebar;

	const openSidebar = () => {
		window.event.stopPropagation();
		document.documentElement.style.overflow = 'hidden';
		isSidebarOpen = true;
	};

	const closeSidebar = () => {
		document.documentElement.style.overflow = '';
		isSidebarOpen = false;
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Escape') {
			closeSidebar();
		}
	};

	let touchStartX, swipeDistance, touchEndX;
	const handleSwipe = (event) => {
		switch (event.type) {
			case 'touchstart':
				touchStartX = event.touches[0].clientX;
				break;
			case 'touchmove':
				touchEndX = event.touches[0].clientX;
				if (location === 'left') {
					swipeDistance = touchStartX - touchEndX;
				} else if (location === 'right') {
					swipeDistance = touchEndX - touchStartX;
				}
				if (isSidebarOpen) {
					const sidebarWidth = sidebar.clientWidth;
					const swipePercentage = Math.min(Math.max(swipeDistance / sidebarWidth, 0), 1);
					const transform =
						location === 'left'
							? `translateX(-${swipePercentage * 100}%)`
							: `translateX(${swipePercentage * 100}%)`;
					requestAnimationFrame(() => {
						sidebar.style.transform = transform;
					});
				}
				break;
			case 'touchend':
				touchEndX = event.changedTouches[0].clientX;
				if (location === 'left') {
					swipeDistance = touchStartX - touchEndX;
				} else if (location === 'right') {
					swipeDistance = touchEndX - touchStartX;
				}
				if (swipeDistance > 100) {
					closeSidebar();
				} else {
					requestAnimationFrame(() => {
						if (sidebar) {
							sidebar.style.transform = '';
						}
					});
				}
				break;
		}
	};

	export const toggleSidebar = () => {
		isSidebarOpen = !isSidebarOpen;
		if (isSidebarOpen) {
			openSidebar();
		} else {
			closeSidebar();
		}
	};
</script>

<svelte:document
	on:touchstart|passive={handleSwipe}
	on:touchmove|passive={handleSwipe}
	on:touchend|passive={handleSwipe}
/>

<div
	class="overlay"
	class:open={isSidebarOpen}
	on:click={closeSidebar}
	on:keypress={handleKeyPress}
/>
<aside
	class="sidebar {className}"
	class:right={location === 'right'}
	class:left={location === 'left'}
	class:open={isSidebarOpen}
	bind:this={sidebar}
>
	<slot />
</aside>

<style>
	.sidebar {
		position: fixed;
		box-shadow: 0 0 0.5em 0.0625em rgba(0, 0, 0, 0.1);
		top: 0;
		width: 40%;
		height: 100%;
		background-color: #f5f5f5;
		padding: 1.25em;
		z-index: 1000;
	}

	.right {
		right: -100%;
		border-radius: 2em 0 0 2em;
		transition: right 0.3s ease;
	}

	.right.open {
		right: 0;
	}

	.left {
		left: -100%;
		border-radius: 0 2em 2em 0;
		transition: left 0.3s ease;
	}

	.left.open {
		left: 0;
	}

	.overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: none;
		backdrop-filter: blur(10px) grayscale(1) brightness(0.5);
		-webkit-backdrop-filter: blur(10px) grayscale(1) brightness(0.5);
		z-index: 999;
	}

	.overlay.open {
		display: block;
	}

	@media (max-width: 767px) {
		.sidebar {
			width: 70%;
		}
	}
</style>
