<script>
	import { createEventDispatcher } from 'svelte';
	export let desktop = false;

	const navLinks = [
		{ title: 'Men', href: '/shop/men' },
		{ title: 'Women', href: '/shop/women' },
		{ title: 'Bespoke', href: '/bespoke' }
	];
	const dispatch = createEventDispatcher();

	const handleClick = () => {
		dispatch('linkClick');
	};
</script>

<ul class={desktop ? 'desktop' : 'mobile'}>
	{#each navLinks as link}
		<li><a href={link.href} on:click={handleClick}>{link.title}</a></li>
	{/each}
</ul>

<style lang="scss">
	.desktop {
		display: flex;
		align-items: center;

		li {
			margin: 0 1em;
		}
	}

	.mobile {
		li {
			border-bottom: 1px solid #eaeaea;
		}

		a {
			display: flex;
			line-height: 4;
		}
	}

	ul {
		list-style: none;
		padding: 0;
	}
	a {
		color: #333;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
</style>
