<script lang>
	import { fly } from 'svelte/transition';
	import { artificialScroll } from '$lib/stores/artificalScroll';
	import BannerMessage from './BannerMessage.svelte';
	import TopBar from './TopBar.svelte';

	let isCompact = false;
	let header;

	let lastScrollTop;
	const threshold = 5;

	$: if ($artificialScroll) {
		isCompact = false;
	}

	const handleScroll = () => {
		const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		if (
			scrollTop > header.offsetHeight + threshold &&
			scrollTop < lastScrollTop - threshold &&
			!isCompact &&
			$artificialScroll === false
		) {
			isCompact = true;
		} else if (
			scrollTop <= header.offsetHeight + threshold ||
			scrollTop > lastScrollTop + threshold
		) {
			isCompact = false;
		}
		lastScrollTop = scrollTop;
	};
</script>

<svelte:window on:scroll|passive={handleScroll} />

<header bind:this={header}>
	<BannerMessage />
	<div class="container">
		<TopBar />
	</div>
	{#if isCompact}
		<div class="container" class:compact={isCompact} transition:fly|global={{ duration: 300, y: -50 }}>
			<TopBar {isCompact} />
		</div>
	{/if}
</header>

<style lang="scss">
	.container {
		display: flex;
		justify-content: space-between;
		z-index: 998;
	}
	.compact {
		position: fixed;
		background-color: rgba(255, 255, 255, 0.9);
		width: 100%;
		left: 0;
		top: 0;
	}
</style>
