<script>
	import { slide } from 'svelte/transition';
	import { page } from '$app/stores';
	import MagnifyingGlassSvg from '$lib/assets/images/magnifying-glass.svg';
	import ImageNew from '$lib/components/ImageNew.svelte';
	import { createSlug } from '$lib/utils/helpers';
	import { onMount } from 'svelte';

	export let showModal;

	let products = $page.data.products;
	let articles = $page.data.articles;
	let selected = 'all';
	let searchInput = '';
	let searchElement;
	let searchResults = [];

	let tabs = [
		{
			label: 'All',
			count: 0,
			type: 'all'
		},
		{
			label: 'Products',
			count: 0,
			type: 'products'
		},
		{
			label: 'Articles',
			count: 0,
			type: 'articles'
		}
	];

	$: {
		if (searchInput.length > 0) {
			searchResults = [
				...products
					.filter((product) => product.name.toLowerCase().includes(searchInput.toLowerCase()))
					.map((product) => ({
						title: product.name,
						description: product.description,
						image: product?.featuredImages[0]?.src || product.variants[0].primaryImage.src,
						link: `/shop/${product.category.name.toLowerCase()}/${createSlug(
							product.productType
						)}/${product.slug}`,
						type: 'products'
					})),
				...articles
					.filter((article) => article.title.toLowerCase().includes(searchInput.toLowerCase()))
					.map((article) => ({
						title: article.title,
						description: article.description,
						image: article.featuredImage.src,
						link: `/blog/${article.slug}`,
						type: 'articles'
					}))
			];
		} else {
			searchResults = [];
		}

		tabs[0].count = searchResults.length;
		tabs[1].count = searchResults.filter((result) => result.link.includes('/shop')).length;
		tabs[2].count = searchResults.filter((result) => result.link.includes('/blog')).length;
	}

	function handleClose() {
		showModal = false;
	}

	onMount(() => {
		if (showModal) {
			searchElement.focus();
		}
	});
</script>

<div class="search-modal">
	<div class="search-header">
		<div class="input-wrapper">
			<img src={MagnifyingGlassSvg} alt="Search" class="search-icon" />
			<input
				type="text"
				placeholder="Search"
				class="search-input"
				bind:value={searchInput}
				bind:this={searchElement}
				aria-label="Search products and articles"
			/>
			<button class="close-button" on:click={handleClose}>Close</button>
		</div>
		<div class="tabs">
			{#each tabs as tab}
				<button
					class="tab"
					class:selected={selected === tab.type}
					on:click={() => (selected = tab.type)}
				>
					<span class="tab-label">{tab.label}</span>
					<span class="tab-count">{tab.count}</span>
				</button>
			{/each}
		</div>
	</div>
	<div class="search-results">
		{#each searchResults.filter((result) => selected === 'all' || result.type.includes(selected)) as searchResult}
			<a
				class="search-result"
				href={searchResult.link}
				on:click={() => (showModal = false)}
				transition:slide|global
			>
				<div class="search-result-image">
					<ImageNew
						src={searchResult.image}
						alt="Search result"
						width={100}
						height={100}
						class="thumbnail"
					/>
				</div>
				<div class="search-result-content">
					<div class="search-result-title">{searchResult.title}</div>
					<div class="search-result-description">{searchResult.description}</div>
				</div>
			</a>
		{/each}
		<div class="search-result skeleton">
			<div class="search-result-image-skeleton" />
			<div class="search-result-content">
				<div class="search-result-title-skeleton" />
				<div class="search-result-description-skeleton" />
			</div>
		</div>
	</div>
</div>

<style>
	.search-modal {
		background-color: #fff;
		width: 100%;
		max-height: 80vh;
		box-shadow:
			0 1px 3px rgba(0, 0, 0, 0.12),
			0 1px 2px rgba(0, 0, 0, 0.24);
		border-radius: 1em;
		overflow: auto;
	}

	.search-header {
		position: sticky;
		top: 0;
		background-color: #fff;
		border-bottom: 1px solid #e8e8e8;
		z-index: 1;
	}

	.input-wrapper {
		display: flex;
		align-items: center;
		padding: 2em 2em 1em 2em;
	}

	.search-input {
		width: 100%;
		border: none;
		font-size: 1.2em;
		font-weight: 900;
	}

	.search-input:focus {
		outline: none;
	}

	.search-icon {
		width: 1.5rem;
		height: 1.5rem;
		margin-right: 1em;
	}

	.close-button {
		font-size: 1em;
		font-weight: 400;
		color: #333;
		text-decoration: underline;
		cursor: pointer;
	}

	.close-button:hover {
		text-decoration-thickness: 2px;
	}

	.tabs {
		display: flex;
		align-items: center;
		margin-left: 2em;
	}

	.tab {
		padding: 1em;
		border-bottom: 2px solid transparent;
		cursor: pointer;
	}

	.tab:focus {
		outline: none;
		border-bottom: 2px solid #e8e8e8;
	}

	.tab:hover {
		border-bottom: 2px solid #e8e8e8;
	}

	.tab-count {
		font-size: 0.9em;
		background-color: #e8e8e8;
		color: #999;
		padding: 0 0.4em;
		border-radius: 4px;
	}

	.selected {
		border-bottom: 2px solid #333 !important;
	}

	.search-result {
		padding: 2em;
		display: flex;
		flex-direction: row;
		border-bottom: 1px solid #e8e8e8;
		cursor: pointer;
		text-align: left;
		align-items: stretch;
		width: 100%;
		color: #333;
		text-decoration: none;
	}

	.search-result:not(.skeleton):focus,
	.search-result:not(.skeleton):hover {
		background-color: #f8f8f8;
		outline: none;
	}

	.search-result-image {
		display: flex;
		align-items: center;
		margin-right: 1em;
		align-self: center;
	}

	:global(.thumbnail) {
		width: 100px !important;
		height: 100px !important;
		max-width: unset !important;
		border-radius: 10px;
		object-fit: cover;
	}

	.search-result-title {
		font-weight: 900;
		margin-bottom: 0.5em;
	}

	.search-result-description {
		font-size: 0.9em;
	}

	.search-result-content {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		flex-grow: 1;
	}

	.search-result-image-skeleton {
		width: 50px;
		height: 50px;
		border-radius: 10px;
		background-color: #f1f1f1;
		margin-right: 1em;
	}

	.search-result-title-skeleton {
		width: 25%;
		height: 1em;
		background-color: #f1f1f1;
		border-radius: 4px;
	}

	.search-result-description-skeleton {
		width: 50%;
		height: 1em;
		background-color: #f1f1f1;
		border-radius: 4px;
	}

	* {
		scrollbar-width: thin;
		scrollbar-color: #e8e8e8 transparent;
	}

	*::-webkit-scrollbar {
		width: 5px;
	}

	*::-webkit-scrollbar-track {
		background: transparent;
		margin: 1em;
		border-radius: 1em;
	}

	*::-webkit-scrollbar-thumb {
		background: #e8e8e8;
		border-radius: 1em;
	}

	*::-moz-scrollbar {
		width: 5px;
	}

	*::-moz-scrollbar-track {
		background: transparent;
		margin: 1em;
		border-radius: 1em;
	}

	*::-moz-scrollbar-thumb {
		background: #e8e8e8;
		border-radius: 1em;
		border: none;
	}

	@media (max-width: 767px) {
		.search-result {
			flex-direction: column;
		}

		.search-result-image {
			margin-right: 0;
			margin-bottom: 1em;
			align-self: flex-start;
		}

		.search-result-title-skeleton {
			margin: 1em 0;
			width: 50%;
		}

		.search-result-description-skeleton {
			width: 100%;
		}
	}
</style>
