<script>
	const messages = [
		'BUY NOW, PAY LATER WITH CLEARPAY',
		'WORLDWIDE SHIPPING AVAILABLE',
		'FREE UK SHIPPING ON ALL ORDERS OVER £150',
		'DESIGNED AND CRAFTED IN LONDON'
	];

	const messagesWithSpaces = messages.concat(messages, messages.slice(0, 2));
</script>

<div class="banner-message">
	{#if messages.length > 1}
		<div class="ticker">
			{#each messagesWithSpaces as message, i}
				<p style="margin-right: 2em">{message}</p>
			{/each}
		</div>
	{:else}
		<p>{messages[0]}</p>
	{/if}
</div>

<style>
	.banner-message {
		color: white;
		height: 1.5em;
		background-color: black;
		display: flex;
		overflow: hidden;
	}

	.ticker {
		display: flex;
		justify-content: space-between;
		animation: scroll 48s linear infinite;
		white-space: nowrap;
	}

	p {
		font-size: 0.7em;
		place-self: center;
		padding: 0 1em;
		margin: 0 15em;
	}

	@keyframes scroll {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(-100%);
		}
	}
</style>
