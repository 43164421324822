<script>
	import MagnifyingGlassSvg from '$lib/assets/images/magnifying-glass.svg';
	import SearchModal from '$lib/components/SearchModal.svelte';
	import { scale, fade } from 'svelte/transition';
	import { browser } from '$app/environment';

	let showModal = false;

	$: if (browser) document.body.style.overflow = showModal ? 'hidden' : 'auto';
</script>

<button on:click={() => (showModal = true)}>
	<img src={MagnifyingGlassSvg} alt="Search" class="search-icon" />
</button>

{#if showModal}
	<button
		class="search-modal-wrapper"
		on:click={() => (showModal = false)}
		transition:fade|global={{ duration: 225 }}
	/>
	<div class="search-modal-container" transition:scale|global={{ duration: 225 }}>
		<SearchModal bind:showModal />
	</div>
{/if}

<style>
	.search-icon {
		width: 20px;
		height: 20px;
	}

	.search-modal-wrapper {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 99;
		background-color: rgba(0, 0, 0, 0.5);
	}

	.search-modal-container {
		position: fixed;
		z-index: 100;
		width: 75vw;
		max-height: 80vh;
		overflow: hidden;
		top: 10vh;
		left: 50%;
		transform: translate(-50%, 0%);
	}

	@media (max-width: 767px) {
		.search-modal-container {
			width: 90%;
		}
		.search-icon {
			width: 21px;
			height: 24px;
		}
	}
</style>
