<script>
	import BagShoppingSvg from '$lib/assets/images/bag-shopping.svg';
	import { shoppingBag } from '$lib/stores/shoppingBag';

	$: shoppingBagCount = $shoppingBag.count;
</script>

{#key shoppingBagCount}
	<a href="/checkout/shopping-bag" aria-label="View your shopping bag" class="shopping-bag-link">
		<img src={BagShoppingSvg} alt="Shopping Bag" class="bag-icon" />
		{#if shoppingBagCount > 0}
			<span class="item-count">{shoppingBagCount}</span>
		{/if}
	</a>
{/key}

<style>
	.shopping-bag-link {
		display: flex;
		align-items: center;
		color: black;
		text-decoration: none;
		font-size: 1.3rem;
		overflow: hidden;
		margin-right: 1rem;
	}

	.item-count {
		font-family: 'Roboto', sans-serif;
		position: relative;
		background-color: black;
		color: #ffffff;
		border-radius: 50%;
		padding: 0.25rem 0.5rem;
		font-size: 0.75rem;
		margin-left: -0.5rem;
		z-index: 1;
		border: 2px solid rgba(255, 255, 255, 0.9);
		animation: wiggle 0.2s ease-in-out;
	}

	.item-count::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: linear-gradient(90deg, rgba(221, 221, 221, 0), rgb(255, 255, 255));
		animation: shimmer 3s cubic-bezier(0.4, 0, 0.2, 1) infinite;
		border-radius: 50%;
		opacity: 0.5;
	}

	.bag-icon {
		width: 20px;
		height: 20px;
	}

	@keyframes wiggle {
		20% {
			transform: translateX(3px);
		}
		40% {
			transform: translateX(-3px);
		}
		60% {
			transform: translateX(2px);
		}
		80% {
			transform: translateX(-2px);
		}
		100% {
			transform: translateX(0);
		}
	}

	@keyframes shimmer {
		0%,
		50% {
			transform: translateX(-200%);
		}
		100% {
			transform: translateX(300%);
		}
	}

	@media (max-width: 767px) {
		.shopping-bag-link {
			margin-right: 0;
		}
		.bag-icon {
			width: 21px;
			height: 24px;
		}
	}
</style>
